@import "~bootswatch/dist/darkly/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/darkly/bootswatch";

.draft-board-table {
  height: calc(100vh - 200px);
  width: 100%;
  overflow: scroll;
}

.draft-board-table table {
  border-spacing: 0;
}

.draft-board-table th {
  position: sticky;
  top: 0;
  z-index: 2;
  background: inherit;
  border-bottom: 1px solid $light;
}

.draft-board-table td {
  width: 80px;
  min-width: 80px;
}

.draft-board-table th:nth-child(1),
.draft-board-table td:nth-child(1) {
  position: sticky;
  left: 0;
  width: 80px;
}

.draft-board-table th:nth-child(2),
.draft-board-table td:nth-child(2) {
  position: sticky;
  left: 80px;
  width: 116px;
}

.draft-board-table th:nth-child(3),
.draft-board-table td:nth-child(3) {
  position: sticky;
  left: 196px;
  width: 196px;
}

.draft-board-table th:nth-child(4),
.draft-board-table td:nth-child(4) {
  position: sticky;
  left: 392px;
  width: 216px;
}

.draft-board-table th:nth-child(5),
.draft-board-table td:nth-child(5) {
  position: sticky;
  left: 608px;
  width: 126px;
}

.draft-board-table th:nth-child(6),
.draft-board-table td:nth-child(6) {
  position: sticky;
  left: 734px;
  width: 126px;
}

.draft-board-table td:nth-child(1),
.draft-board-table td:nth-child(2),
.draft-board-table td:nth-child(3),
.draft-board-table td:nth-child(4),
.draft-board-table td:nth-child(5),
.draft-board-table td:nth-child(6) {
  z-index: 3;
  background: inherit;
}

.draft-board-table th:nth-child(1),
.draft-board-table th:nth-child(2),
.draft-board-table th:nth-child(3),
.draft-board-table th:nth-child(4),
.draft-board-table th:nth-child(5),
.draft-board-table th:nth-child(6) {
  background: inherit;
  z-index: 5;
}

.draft-board-table td:nth-child(6),
.draft-board-table th:nth-child(6) {
  border-right: 1px solid $light;
}